@font-face {
  font-family: "gilmer";
  src: url("/fonts/Gilmer_Regular.otf");
}
@font-face {
  font-family: "gilmer-bold";
  src: url("/fonts/Gilmer_Bold.otf");
}

@font-face {
  font-family: "gilmer-heavy";
  src: url("/fonts/Gilmer_Heavy.otf");
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
* {
  font-family: "gilmer", sans-serif;
}

html {
  body {
    background: var(--body-bg);
    // background: #f4f4f4;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: #fff;
        // opacity: 0.7;
      }
    }

    @media (max-width: 575px) {
      font-size: 16px;
    }
  }
}

:root {
  --card-bg: #201700;
  --btn-bg: rgb(61, 61, 61);
  --text: #fff;
  --text-label: #aaa;
  --text-highlight: #e3c530;
  --menu-bg: #2d2101;
  --menu-active-bg: #3a2e10;

  --golden: linear-gradient(180deg, #a46f0c -19.74%, #ffe64a 118.19%);
  --border: #3c2c00;
  --label-bg: #322a14;
  --label-bg2: #3b331f;

  // Simple Colors
  --blue-color: #1b2e50;
  --body-bg: #f4f4f4;
  --box-color: #1b2e50;
  --box-bg: #fff;
  --div-bg: #fcfcfc;
}

body {
  &.dark-theme {
    --blue-color: #222;
    --body-bg: #4a4a4a;
    --box-bg: #222;
    --box-color: #fff;
    --div-bg: #333;
  }
}
#root {
  min-height: 100vh;

  button {
    background-color: var(--btn-bg);
    color: var(--text);
    font-weight: 600;
    border: 0;
    outline: none;
    border-radius: 5px;
    padding: 10px 24px;
    font-size: 18px;
    text-transform: uppercase;

    @media (max-width: 575px) {
      font-size: 15px;
      padding: 6px 15px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .golden-bg {
    max-width: 210px;
    background: #fabc44;
    color: #fff;
    font-weight: 700;
    box-shadow: none;
    outline: none;
    height: 50px;
    border: none;
    border-radius: 13px;
    width: 100%;
    white-space: nowrap;
  }

  .golden {
    background: var(--golden);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .counter {
    color: var(--box-color);
    font-size: 17px;
    margin-top: 10px;
    font-family: "gilmer-heavy";
  }
  .features {
    width: 100% !important;
    > .col,
    > [class*="col-"] {
      // padding-right: 0;
      padding-left: 0;
    }
  }
  .content-details {
    width: 100%;
    color: var(--box-color);

    span {
      color: var(--box-color);
    }
  }
}
.toggle-btn {
  position: fixed;
  top: 150px;
  right: 0;
  max-width: 150px;
  width: 100%;
  margin-left: auto;
  height: 50px;
  background: var(--blue-color);
  font-size: 18px;
  padding: 0px;
  line-height: 26px;
  height: 50px;
  z-index: 10;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: var(--blue-color);
  }
}
.menu-icon {
  color: rgb(27, 46, 80);
  font-size: 30px;
  cursor: pointer;
}

@media (max-width: 567px) {
  .header-icons {
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;
  }
  .me-0, .golden-bg {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin: 10px 0px;
  }
  .toggle-btn{
    top: 260px;
  }
}


